import * as React from "react"

import { cn } from "@/lib/utils"

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    const mobilePattern = "^[0-9]+$";
    const namePattern = "^[a-zA-Z .]{3,30}$";
    const emailPattern = "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$";
    return (
      <input
        type={type}
        className={cn(
          "flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
        pattern={type === "tel" ? mobilePattern : type === "email" ? emailPattern : namePattern}
        onInvalid={(e: React.FormEvent<HTMLInputElement>) => {
          const target = e.target as HTMLInputElement;
          if (type === "tel") {
            target.setCustomValidity("Please enter a valid mobile number");
          } else if (type === "text") {
            target.setCustomValidity("Please enter a valid name. \n Length should be between 3 to 30 characters. \n No special characters allowed.");
          } else if (type === "email") {
            target.setCustomValidity("Please enter a valid email address");
          } else {
            target.setCustomValidity("");
          }
        }}
        onInput={(e: React.FormEvent<HTMLInputElement>) => e.currentTarget.setCustomValidity("")}
      />
    )
  }
)
Input.displayName = "Input"

export { Input }
